const initialState = {
  data: null
}

const  getSkills = (state = initialState, action) => {
  switch(action.type){
      case 'GET_SKILLS':
          return {
              ...state,
              data: action.payload
          };
      default:
          return state
  }
}

export default getSkills
