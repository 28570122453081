import { applyMiddleware,createStore, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers'

const initialState = {
  sidebarShow: 'responsive'
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case 'set':
      return {...state, sidebarShow: action.payload }
    default:
      return state
  }
}

const newReducer = combineReducers({
  rootReducer: rootReducer,
  changeState: changeState
})

// const store = createStore(changeState)
const store = createStore(newReducer,applyMiddleware(thunkMiddleware))
export default store
