import { combineReducers } from 'redux';
import adminReducer from './adminReducer';
import applicationReducer from './applicationReducer';
import postReducer from './postReducer';
import clientReducer from './clientReducer';
import freelancerReducer from './freelancerReducer';
import logInReducer from './loginReducer';
import permissionsReducer from './permissionReducer';
import testimonialReducer from './testimonialReducer';
import categoriesReducer from './categoriesReducer';
import skillsReducer from './skillsReducer';
import documentsReducer from './documentReducer';
import addExperienceReducer from './addExperience';
import freelancerAdvanceReducer from './advanceFreelancer';
import educationReducer from './educationReducaer';
import portfolioReducer from './portfolioReducer';

const rootReducer =  combineReducers({
  adminData: adminReducer,
  applicationData: applicationReducer,
  postData: postReducer,
  clientData: clientReducer,
  freelancerData: freelancerReducer,
  logInToken: logInReducer,
  permissionsData: permissionsReducer,
  testimonialData: testimonialReducer,
  categoriesData: categoriesReducer,
  skillsData: skillsReducer,
  documentsData: documentsReducer,
  addExperience: addExperienceReducer,
  freelancerAdvance:freelancerAdvanceReducer,
  education: educationReducer,
  portfolio: portfolioReducer,
})

export default rootReducer
