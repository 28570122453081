const initialState = {
  data: null
}

const  getFreelancerData = (state = initialState, action) => {
  switch(action.type){
      case 'GET_FREELANCER_ADVANCE_DATA':
          return {
              ...state,
              data: action.payload
          };
      default:
          return state
  }
}

export default getFreelancerData
