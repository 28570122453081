const data = localStorage.getItem("user");
let logInData;
logInData = { ...JSON.parse(data) };
let initialState;
if (data !== undefined) {
  initialState = {
    data: logInData,
  };
} else {
  initialState = {
    data: null,
  };
}

const logIn = (state = initialState, action) => {
  switch (action.type) {
    case "LOGGED_IN":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default logIn;
