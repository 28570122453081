const initialState = {
  data: null
}

const  getTestimonialData = (state = initialState, action) => {
  switch(action.type){
      case 'GET_TESTIMONIALS':
          return {
              ...state,
              data: action.payload
          };
      default:
          return state
  }
}

export default getTestimonialData
