const initialState = {
  data: null,
  subData: null,
  subsubData: null
};

const getCategories = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CATEGORIES":
      return {
        ...state,
        data: action.payload,
      };
    case "GET_SUB_CATEGORIES":
      return {
        ...state,
        subData: action.payload,
      };
    case "GET_SUB_SUB_CATEGORIES":
      return {
        ...state,
        subsubData: action.payload,
      };
    default:
      return state;
  }
};

export default getCategories;
