const initialState = {
  data: [
    {
      degree: "",
      organization: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
      isCurrentlyWorking: false,
      summary: "",
    },
  ],
};

const getEducation = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_EDUCATION":
      const newExp = {
        degree: "",
        organization: "",
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        isCurrentlyWorking: false,
        summary: "",
      };
      const newData = state.data;
      newData.push(newExp);
      return {
        ...state,
        data: newData,
      };
    case "SET_EDUCATION":
      return {
        ...state,
        data: action.payload
      }
    case "CHANGE_EDUCATION":
      const index = action.payload.index;
      const changeData = action.payload;
      delete changeData["index"]
      state.data[index] = changeData;
      return {
        ...state,
        data: state.data
      };
    case "REMOVE_EDUCATION":
      const removeData = state.data;
      removeData.splice(action.payload, 1);
      return {
        ...state,
        data: state.data,
      };
    default:
      return state;
  }
};

export default getEducation;
