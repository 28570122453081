const initialState = {
  permissions: {
    USER: { CLIENT: {CREATE: false, UPDATE: false, READ: true, DELETE: false}, FREELANCER: {CREATE: false, UPDATE: false, READ: true, DELETE: false} },
    PROJECT: { POST: {CREATE: false, UPDATE: false, READ: true, DELETE: false}, APPLICATION: {CREATE: false, UPDATE: false, READ: true, DELETE: false} },
    OTHER: { DOCUMENTS: {CREATE: false, UPDATE: false, READ: true, DELETE: false}, CATEGORIES: {CREATE: false, UPDATE: false, READ: true, DELETE: false}, SKILLS: {CREATE: false, UPDATE: false, READ: true, DELETE: false} }
  }
}

const  changePermissions = (state = initialState, action) => {
  switch(action.type){
      case 'CHANGE_PERMISSION':
          return {
              ...state,
              permissions: action.payload
          };
      default:
          return state
  }
}

export default changePermissions
